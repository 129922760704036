import { createSharedComposable } from '@vueuse/core'
import type { From } from '~/types/interfaces'

const _useDashboard = () => {
    const route = useRoute()
    const isMultipleVehicleSelectOpen = ref(false)
    const isSearchVehicleOpen = ref(false)
    const isMobileNavBarOpen = ref(false) 
    const isManualsSlideOverOpen = ref(false) 
    const searchVehicleFrom = ref<From>('dashboard')
    const isCgvOpen = ref(false)

    watch(() => route.fullPath, () => {
        isMultipleVehicleSelectOpen.value = false
        isSearchVehicleOpen.value = false
        isMobileNavBarOpen.value = false
        isManualsSlideOverOpen.value = false
        searchVehicleFrom.value = 'dashboard'
        isCgvOpen.value = false
    })

    return {
        isMultipleVehicleSelectOpen,
        isSearchVehicleOpen,
        isMobileNavBarOpen,
        isManualsSlideOverOpen,
        searchVehicleFrom,
        isCgvOpen
    }
}

export const useDashboard = createSharedComposable(_useDashboard)